import { Core } from "../core";
import { useStore } from "../store";

export const forceNextStep = function (this: Core) {
  const currentStepId = useStore.getState().currentStep?.id;

  if (currentStepId) {
    this.subscription?.perform("force_next_step", { stepId: currentStepId });
  }
};
