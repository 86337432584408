import React from "react";
import { store } from "../../../core/store";
import { replacePlaceholder } from "../../../lib/getFeedbackURL";
import { isIframeStep } from "../../../types/type_guards";

export const IFrameStep = () => {
  const currentStep = store.use.currentStep();
  const iframeVisible = store.use.iframeVisible();
  const transitioning = store.use.transitioning();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();

  if (isIframeStep(currentStep) && !transitioning) {
    const srcUrl = replacePlaceholder(currentStep.settings.url, {
      hashedInvitationID: invitation.hashedID,
      yourID: currentUser.id,
    });
    console.debug(`rendering iframe step. srcUrl: ${srcUrl}`);
    return (
      <iframe
        key="iframe"
        src={srcUrl}
        style={{
          zIndex: 12,
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "0px",
          display: iframeVisible ? "block" : "none",
        }}
      />
    );
  } else {
    return null;
  }
};
