import styled from "styled-components";

import React, { FC, useState, useEffect } from "react";
import { Button, LoadingButton } from "../ButtonV2/Button";
import { isContentStep } from "../../../types/type_guards";
import { FormattedMessage } from "react-intl";

import ContinueEarly from "./ContinueEarly";
import { store } from "../../../core/store";
import { doAction } from "../../../core/SessionChannel/doAction";

const ActionWrapper = styled.div`
  width: 100%;

  visibility: visible;
  text-align: center;
  transform: translateY(0px);
  opacity: 1;
  max-height: 200px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    pointer-events: auto; // So that the buttons are clickable even though parent has pointer-events: none
  }

  &.autohide {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0;
    transform: translateY(100%);
    float: left;
    opacity: 0;
    margin: 0;
  }

  &.one-on-one {
    bottom: 40px;
    position: absolute;
    z-index: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: transparent;
  gap: 10px;

  padding: 10px 0;

  text-align: center;

  opacity: 1;

  &.autohide {
    opacity: 0 !important;
    bottom: 0;
  }

  top: 0;

  transition:
    top 2s,
    background-color 2s,
    padding 2s,
    opacity 2s;

  &.loud {
    background-color: #222;
    position: relative;
    top: -250px;
    width: auto;
    padding: 40px;
    border-radius: 130px;
  }

  &.inPerson {
    top: -10px;
  }

  &.inPerson.loud {
    top: -50px;
    width: auto;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.hideMe {
    opacity: 0;
    bottom: 0;
    position: absolute;
  }

  &.disconnected {
    opacity: 0.3;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;

  flex-shrink: 0;

  button {
    display: block !important;
  }

  &.autohide {
    opacity: 0 !important;
    bottom: 0;
  }

  opacity: 1;
  transition: opacity 0.5s;

  &.hideMe {
    opacity: 0;
    bottom: 0;
  }
`;

const BUTTON_DELAY_SECONDS = process.env.NODE_ENV == "cypress-test" ? 1 : 3;
export const TIME_INTERVAL_BUTTON_PULSING = 10;
const TIME_INTERVAL_BUTTON_LOUD = 5;

interface Props {
  wrapperClasses: string;
  disableAutoHide: (disable: boolean) => void;
}

const Actions: FC<Props> = ({ wrapperClasses, disableAutoHide }) => {
  const locale = store.use.locale();
  const currentStep = store.use.currentStep();
  const stepData = store.use.stepData();
  const roleData = store.use.roleData();
  const stepContent = store.use.stepContent();
  const timeOnStep = store.use.timeOnStep();
  const sessionConsumerIsConnected = store.use.sessionConsumerIsConnected();
  const inPerson = store.use.inPerson();
  const stepTiming = store.use.stepTiming();
  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();
  const core = store.use.core();

  const youDone = stepData?.[currentUser.id]?.done;
  const [continueEarly, setContinueEarly] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<string | null>(null);

  let role = roleData[currentUser.id]?.role;
  if (isContentStep(currentStep) && currentStep.shareContent) {
    role = "EVERYONE_ELSE";
  }

  const content = isContentStep(currentStep) ? currentStep.content[role] : undefined;

  //Reset everything in case step or role changes
  useEffect(() => {
    setContinueEarly(false);
  }, [currentStep, role]);

  useEffect(() => {
    if (timeOnStep >= stepTiming) {
      disableAutoHide(true);

      if (!inPerson && !invitation.autoAdvanceSteps) {
        setContinueEarly(true);
      }
    }
  }, [timeOnStep]);

  if (!isContentStep(currentStep)) {
    return;
  }

  let buttonDelay = BUTTON_DELAY_SECONDS;

  if (isContentStep(currentStep) && currentStep.settings) {
    if (currentStep.settings.buttonHideDuration === 0) {
      buttonDelay = 0;
    } else if (currentStep.settings.buttonHideDuration) {
      buttonDelay = currentStep.settings.buttonHideDuration;
    }
  }

  const classes: string[] = [];

  if (inPerson) classes.push("inPerson");

  if (stepTiming > 0) {
    if (timeOnStep >= stepTiming - TIME_INTERVAL_BUTTON_LOUD) {
      classes.push("loud");
    }

    // Auto-advance to the next step if we're out of time and duration is enforced, but not in-person
    // If stepTiming is 0 or missing, then don't auto-advance
    if (!youDone && stepTiming && !inPerson && timeOnStep >= stepTiming && invitation.autoAdvanceSteps) {
      doAction(core, "done", currentStep.id, currentUser.id, "", setButtonLoading);
    }

    if ((inPerson || continueEarly) && timeOnStep >= stepTiming - TIME_INTERVAL_BUTTON_PULSING) {
      classes.push("pulsing");
    } else {
      if (timeOnStep < buttonDelay && !continueEarly) {
        classes.push("hideMe");
      } else {
        classes.push("");
      }
    }
  } else if (timeOnStep < buttonDelay && !continueEarly) {
    classes.push("hideMe");
  }

  if (!sessionConsumerIsConnected) {
    classes.push("disconnected");
  }

  const updateContinueEarly = () => {
    setContinueEarly(true);
  };

  const clickButton = (buttonId: string, actionOnClick: ActionConsequence) => {
    setButtonLoading(buttonId);
    doAction(core, actionOnClick, currentStep.id, currentUser.id, "", setButtonLoading);
  };

  if (stepContent && content && isContentStep(currentStep)) {
    if (stepContent.actions.length > 0) {
      const a = invitation.autoAdvanceSteps;
      const b = !stepData?.[currentUser.id]?.done && timeOnStep <= stepTiming;
      const c = !continueEarly;
      const d = invitation.suggestedDurationPrompts;
      const e = !inPerson;
      const showTimingBar = stepTiming && (a || b) && c && d && e;
      // console.debug(`show timing bar: ${showTimingBar}, a: ${a}, b: ${b}, c: ${c}, d: ${d}, a: ${e}`);

      return (
        <ActionWrapper className={wrapperClasses + " " + stepTiming + " " + timeOnStep}>
          <>
            {showTimingBar ? (
              <ContinueEarly
                secondsCountdownTime={stepTiming}
                hideBarTime={stepTiming - 1}
                timeOnStep={timeOnStep}
                locale={locale}
                setContinueEarly={updateContinueEarly}
                autoAdvanceSteps={invitation.autoAdvanceSteps}
              />
            ) : (
              <Wrapper className={classes.join(" ")}>
                {youDone ? (
                  <ButtonWrapper key={"waiting-for-wrapper" + currentStep.id}>
                    <Button key={"waiting-for" + currentStep.id} className="secondary">
                      <FormattedMessage
                        description="Actions button waiting for partner"
                        id="actions.content_waiting_for_partner"
                        defaultMessage="Waiting for partner"
                      />
                    </Button>
                  </ButtonWrapper>
                ) : (
                  stepContent.actions.map((action, idx) => {
                    const buttonId = "action-button-id-" + idx;
                    return (
                      <ButtonWrapper key={"wrapper-" + action.text}>
                        <LoadingButton
                          key={action.text}
                          onClick={() => clickButton(buttonId, action.onClick)}
                          className={classes.join(" ")}
                          loading={buttonLoading == buttonId}
                        >
                          {action.text}&nbsp;
                          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                          {stepTiming ? `(${Math.max(0, stepTiming - timeOnStep)})` : ""}
                        </LoadingButton>
                      </ButtonWrapper>
                    );
                  })
                )}
              </Wrapper>
            )}
          </>
        </ActionWrapper>
      );
    }
  }

  return <></>;
};

export default Actions;
