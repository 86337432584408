import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { muteParticipant } from "../../../core/SessionChannel/muteParticipant";
import styled from "styled-components";
import { useParticipantProperty } from "@daily-co/daily-react";
import { SmallButton } from "./ManageParticipantModal";
import { admitParticipant } from "../../../core/SessionChannel/admitParticipant";

const Participant = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 5px 5px 5px 0;
  overflow: visible;

  i {
    margin-left: 6px;
    font-size: 12px;
    color: #555;
  }
`;

interface Props {
  participant: Participant;
  mutingEnabled: boolean;
  yourId: number;
  index: number;
  setShowDropParticipantsModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setParticipant: (
    value: ((prevState: Participant | undefined) => Participant | undefined) | Participant | undefined,
  ) => void;
  canManageWaitingRoom: boolean;
}

export const ManageParticipantItem: FC<Props> = ({
  participant,
  mutingEnabled,
  yourId,
  index,
  setShowDropParticipantsModal,
  setParticipant,
  canManageWaitingRoom,
}) => {
  const showMute = mutingEnabled && participant.location === "session";
  const inWaitingRoom = participant.location === "waitingRoom";
  const trackState = useParticipantProperty(participant.dailySessionID, "tracks");
  const hasAudio = trackState && trackState.audio.state === "playable";
  return (
    <Participant key={`drop-user-${index}`} data-testid={`participant-${participant.name}`}>
      <div style={{ display: "flex" }}>
        <div key={`drop-user-name-${index}`}>
          {participant.name}
          {participant.authorizations.length > 0 && (
            <FormattedMessage
              description="Text indicating user role"
              id="manage_participant_modal.role"
              defaultMessage=" ({role})"
              values={{ role: participant.authorizations.join(",") }}
            />
          )}
          <i>
            {participant.location === "ready-room" ? (
              <FormattedMessage
                description="Participant location is getting ready"
                id="manage_participant_modal.getting_ready"
                defaultMessage=" (Getting ready)"
              />
            ) : (
              ""
            )}
          </i>
          {inWaitingRoom && (
            <FormattedMessage
              description="Text indicating your user tile as you"
              id="manage_participant_modal.tile.you"
              defaultMessage="(Waiting room)"
            />
          )}
          {participant?.id == yourId && (
            <FormattedMessage
              description="Text indicating your user tile as you"
              id="manage_participant_modal.tile.you"
              defaultMessage="(you)"
            />
          )}
          &nbsp;
        </div>
        {participant.location === "offline" ? (
          <i>
            <FormattedMessage
              description="Participant location is offline"
              id="manage_participant_modal.offline"
              defaultMessage="Offline"
            />
          </i>
        ) : (
          ""
        )}
      </div>
      {inWaitingRoom && canManageWaitingRoom ? (
        <SmallButton
          onClick={() => {
            admitParticipant(participant.id);
          }}
          style={{ width: "auto" }}
        >
          <FormattedMessage
            description="Participant button text to admit a participant into the session from the waiting room"
            id="manage_participant_modal.admit"
            defaultMessage="Admit"
          />
        </SmallButton>
      ) : (
        showMute && (
          <SmallButton
            onClick={() => {
              muteParticipant(participant.id, hasAudio);
            }}
            style={{ width: "auto" }}
          >
            {hasAudio ? (
              <FormattedMessage
                description="Participant button text to mute a participant"
                id="manage_participant_modal.mute"
                defaultMessage="Mute"
              />
            ) : (
              <FormattedMessage
                description="Participant button text to unmute a participant"
                id="manage_participant_modal.unmute"
                defaultMessage="Unmute"
              />
            )}
          </SmallButton>
        )
      )}
      {participant.location === "offline" && (
        <SmallButton
          onClick={() => {
            setShowDropParticipantsModal(true);
            setParticipant(participant);
          }}
          style={{ width: "auto" }}
        >
          <FormattedMessage
            description="Participant button text to drop participant"
            id="manage_participant_modal.drop"
            defaultMessage="Drop"
          />
        </SmallButton>
      )}
    </Participant>
  );
};
