import React, { FC } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import { Forward, Back } from "../../../assets/icons/Icons";
import { secondsToTimeString } from "../Badge/LobbyStatusBadge";

export const ButtonContainer = styled.button`
  background-color: #ee6e5e;
  font-weight: bold;
  height: calc(100% + 1px);
  width: 45px;
  position: absolute;
  color: #fff;
  right: 0px;
  top: 0px;
  border: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #cc4e2e;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  box-sizing: border-box;

  &.back {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 0px;
    right: auto;
  }
`;

const Flow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 9px 10px 17px;
  background-color: #fdf1e4;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #ddd1b4;

  &:hover {
    ${ButtonContainer} {
      background-color: #de5e4e;
      border-bottom: 1px solid #ae2e1e;
    }
  }

  &.back {
    padding-left: 60px;
  }

  &.forward {
    padding-right: 60px;
  }
`;

const FlowInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  flow: LobbyInvitation;
  mode: "forward" | "back";
}

export function getFlowTimeString(flow: LobbyInvitation) {
  if (!flow.minDuration || !flow.maxDuration) {
    return secondsToTimeString(flow.duration);
  } else if (flow.minDuration != flow.maxDuration) {
    const minDurationString = secondsToTimeString(flow.minDuration);
    const maxDurationString = secondsToTimeString(flow.maxDuration);
    return `${minDurationString} - ${maxDurationString}`;
  } else {
    return secondsToTimeString(flow.minDuration);
  }
}

export const FlowItem: FC<Props> = ({ flow, mode }) => {
  const intl = useIntl();
  const flowDurationString = getFlowTimeString(flow);
  const numGroupsString = flow.numGroups
    ? flow.numGroups == 1
      ? `${flow.numGroups} group`
      : `${flow.numGroups} groups`
    : "groups";

  const minGroupSize = flow.minGroupSize || flow.min;
  const maxGroupSize = flow.maxGroupSize || flow.max;

  return (
    <Flow
      key={flow.hashId}
      className={mode}
      data-testid={`flow-${flow.hashId}`}
      onClick={() => {
        if (mode === "forward")
          useStore.setState({
            selectedFlow: flow,
            selectedRoom: undefined,
            currentFlowConfig: Object.assign({}, flow),
          });
        if (mode === "back")
          useStore.setState({ selectedFlow: undefined, selectedRoom: undefined, currentFlowConfig: undefined });
        modalClear("RoomsConfig");
      }}
    >
      {mode === "back" && (
        <ButtonContainer
          className="back"
          aria-label={intl.formatMessage({
            description: "Back to Flow List",
            id: "flow_item.back",
            defaultMessage: "Back to Flow List",
          })}
          title={`Back to Flow List`}
        >
          <Back fill={"#fff"} />
        </ButtonContainer>
      )}
      <FlowInfo>
        <div
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          {flow.name}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#4d4a4a",
          }}
        >
          {flow.isGather ? (
            <FormattedMessage
              description="Flows List text for creating a group with all particpants"
              id="flows_list.groups_gather_all"
              defaultMessage="Gather all"
            />
          ) : minGroupSize === maxGroupSize ? (
            <FormattedMessage
              description="Flows List text for creating rooms with a set minimum number of participants"
              id="flows_list.min_groups"
              defaultMessage="{duration} min / {numGroups} of {min}"
              values={{ duration: flowDurationString, min: minGroupSize, numGroups: numGroupsString }}
            />
          ) : (
            <FormattedMessage
              description="Flows List text for creating rooms in a range of min to max participants with set preference"
              id="flows_list.min_max_groups"
              defaultMessage="{duration} min / {numGroups} of {min} - {max} (prefer: {preferred})"
              values={{
                duration: flowDurationString,
                min: minGroupSize,
                max: maxGroupSize,
                preferred: flow.preferred,
                numGroups: numGroupsString,
              }}
            />
          )}
        </div>
      </FlowInfo>
      {mode === "forward" && (
        <ButtonContainer
          aria-label={intl.formatMessage(
            {
              description: "Select {flowName}",
              id: "flow_item.select",
              defaultMessage: "Select {flowName}",
            },
            { flowName: flow.name },
          )}
          title={`Select ${flow.name}`}
        >
          <Forward fill={"#fff"} />
        </ButtonContainer>
      )}
    </Flow>
  );
};
