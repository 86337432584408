import React, { FC, useState } from "react";
import toast from "react-simple-toasts";
import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Spinner } from "../../../assets/icons/Icons";
import { Overlay, Card, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { store, useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import postBreakoutReturn from "../../../lib/requests/postBreakoutReturn";

export const ConfirmReturnRoomModal: FC = () => {
  const selectedRoom = store.use.selectedRoom();
  const selectedParticipant = store.use.selectedParticipant();
  const rooms = store.use.rooms();

  const selectedRoomName = rooms.find((room) => room.hashId === selectedRoom)?.roomName;

  const [loadingClose] = useState<boolean>(false);

  const handleCloseModal = () => {
    useStore.setState({
      selectedRoom: null,
    });
    modalClear("ConfirmReturnRoom");
  };

  // Closes a room or all rooms depending on roomHashId.
  // If roomHashId is null, all rooms are closed.
  const handleCloseRoom = () => {
    return async () => {
      try {
        await postBreakoutReturn({
          roomInvitationHashId: selectedParticipant?.breakoutRoomHashId as string,
          inqUserId: selectedParticipant?.id as number,
        });
      } catch {
        toast("Unable to send participant to room. Please try again in a few minutes.");
      }
    };
  };

  return (
    <>
      <Card>
        <CardHeader>
          <FormattedMessage
            description="Confirm return room modal"
            id="breakout_modal.confirm_return_room"
            defaultMessage="Are you sure you want to return {participantName} to {roomName}?"
            values={{
              participantName: selectedParticipant?.name ?? "the participant",
              roomName: selectedRoomName ?? "the lobby",
            }}
          />
        </CardHeader>
        <CardFooter>
          <Button onClick={handleCloseModal} className="link" style={{ color: "black" }}>
            <FormattedMessage
              description="Confirm return room modal cancel button"
              id="breakout_modal.cancel_return_room_button"
              defaultMessage="Cancel"
            />
          </Button>
          <Button onClick={handleCloseRoom()} disabled={loadingClose}>
            {loadingClose ? (
              <Spinner />
            ) : (
              <FormattedMessage
                description="Confirm return room modal confirm button"
                id="breakout_modal.confirm_return_room_button"
                defaultMessage="Return"
              />
            )}
          </Button>
        </CardFooter>
      </Card>
      <Overlay onClick={handleCloseModal} />
    </>
  );
};
