import React, { FC, useEffect, useRef, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useLocalParticipant, useVideoTrack, useDevices, useDaily } from "@daily-co/daily-react";
import { Branding } from "../UI/Branding/Branding";
import { SetupInner, SetupWrapper, InstructionWrapper } from "./styles";
import { FeelingSurvey } from "./FeelingSurvey";
import { CameraArea } from "./CameraArea";
import { submitFeeling } from "../../core/ScheduleApp/submitFeeling";
import { LoadingButton } from "../UI/ButtonV2/Button";
import { store } from "../../core/store";

export const Prejoin: FC = () => {
  console.debug("[session-ui]: Prejoin render");

  const callObject = useDaily();

  const invitation = store.use.invitation();
  const core = store.use.core();

  const [joining, setJoining] = useState(false);
  const localParticipant = useLocalParticipant();
  const { cameras } = useDevices();
  const videoTrack = useVideoTrack(localParticipant?.session_id || "");
  const videoElement = useRef<HTMLVideoElement>(null);

  const skipFeelingSurvey = !!invitation?.skipFeelingSurvey;
  const mutedVideo = videoTrack.isOff;
  const selectedCamera = cameras.find((x) => x.selected);

  useEffect(() => {
    core.subscription?.perform("update_location", { location: "ready-room" });
  }, []);

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    videoElement?.current &&
      (videoElement.current.srcObject = videoTrack.persistentTrack && new MediaStream([videoTrack?.persistentTrack]));
  }, [videoTrack.persistentTrack]);

  const [cameraState, setCameraState] = useState({
    enabled: !mutedVideo,
    activating: true,
    active: false,
    state: "camera_off",
    message: (
      <FormattedMessage
        description="Prejoin setup camera start"
        id="prejoin.setup_starting_camera"
        defaultMessage="Starting Camera"
      />
    ),
  });

  const cameraStarted = useCallback(() => {
    setCameraState({
      ...cameraState,
      activating: false,
      active: true,
      state: "camera_started",
      message: (
        <FormattedMessage
          description="Prejoin setup camera started state"
          id="prejoin.setup_camera_started"
          defaultMessage="Camera started"
        />
      ),
    });
  }, [cameraState]);

  useEffect(() => {
    if (mutedVideo && callObject?.localVideo() !== undefined) {
      if (videoElement.current) {
        videoElement.current.srcObject = null;
      }

      setCameraState((cameraState) => ({
        ...cameraState,
        activating: false,
        active: false,
        enabled: false,
        state: "camera_off",
        message: (
          <FormattedMessage
            description="Prejoin setup camera off state"
            id="prejoin.setup_camera_off"
            defaultMessage="Camera Off"
          />
        ),
      }));
    } else {
      setCameraState((cameraState) => ({
        ...cameraState,
        activating: true,
        active: false,
        enabled: false,
        state: "camera_off",
        message: (
          <FormattedMessage
            description="Prejoin setup starting camera from state off"
            id="prejoin.setup_state_off_starting_camera"
            defaultMessage="Starting Camera"
          />
        ),
      }));
    }
  }, [mutedVideo]);

  const joinDigital = async () => {
    setJoining(true);

    core.subscription?.perform("switch_in_person_mode", {
      in_person: false,
    });

    await core.joinCall();
    setJoining(false);
  };

  return (
    <SetupWrapper>
      <SetupInner>
        <Branding fill={"white"} style={{ marginBottom: "15px" }} />

        <CameraArea
          cameraState={cameraState}
          selectedCamera={selectedCamera}
          cameraErrorMessage={""}
          cameraStarted={cameraStarted}
          videoTrack={videoTrack}
          localParticipant={localParticipant}
          videoElement={videoElement}
        />

        {!skipFeelingSurvey && (
          <FeelingSurvey
            submitFeeling={async (score: number) => {
              await submitFeeling(score, core);
            }}
          />
        )}

        {skipFeelingSurvey && (
          <InstructionWrapper>
            <LoadingButton id="joinButton" disabled={joining} loading={joining} onClick={joinDigital}>
              <FormattedMessage
                description="Prejoin button to join call digitally"
                id="prejoin.join_digital_button"
                defaultMessage="Join"
              />
            </LoadingButton>
          </InstructionWrapper>
        )}
      </SetupInner>
    </SetupWrapper>
  );
};
