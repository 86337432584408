import styled from "styled-components";

import React, { FC, useEffect, useState } from "react";
import { Button, LoadingButton } from "../ButtonV2/Button";

import { store, useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { doAction } from "../../../core/SessionChannel/doAction";
import { FormattedMessage } from "react-intl";
import ContinueEarly from "../Actions/ContinueEarly";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  transition: all 0.5s ease;
  top: -100px;
  opacity: 0;

  &.showContent {
    top: 0;
    opacity: 1;
  }

  &.inPerson {
    height: calc(100% - 60px);
    top: 0;
    &.showContent {
      top: 100px;
    }

    p {
      color: #2a2a2a;
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.35;
    color: white;
    margin: 0 auto;
    white-space: pre-line;
    font-family: "ITC Clearface", sans-serif;
    z-index: 10;
    max-width: 90%;
  }

  & .spinner {
    display: inline-block;
    position: relative;
    top: 7px;
    left: 15px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: 7;
  opacity: 0.6;
  pointer-events: none;
`;

const ScoreText = styled.p`
  margin-bottom: 30px !important;
`;

const Description = styled.div`
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const DescriptionLow = styled.span``;

const DescriptionHigh = styled.span``;

const ScoreWrapper = styled.div<ScoresWrapperProps>`
  z-index: 8;
  position: relative;
  border-radius: 10px;

  background-color: #111;
  padding: 20px;

  width: calc(100% - 20px);
  max-width: 600px;
  box-sizing: border-box;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &.inPerson {
    background-color: transparent;
  }

  button {
    width: 100%;
    display: flex;

    justify-content: center;
    font-weight: 500;

    border: 0;
    padding: 10px 18px;

    pointer-events: auto;

    margin: auto;

    text-align: center;
    font-size: 16px;

    @media (max-width: 320px) {
      padding: 4px !important;
      font-size: 14px;
    }

    &:hover {
      background-color: #f19903;
    }

    &.disabled {
      background-color: rgba(25, 25, 25, 0.8);
      &:hover {
        background-color: rgba(25, 25, 25, 0.8);
      }

      cursor: default;
    }
  }
`;

interface ScoresWrapperProps {
  width: number;
}
interface ScoresListProps {
  columns: number;
}

export const ScoresList = styled.div<ScoresListProps>`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: ${Array(5 + 1)
      .fill("")
      .join("1fr ")};
  }

  @media (max-width: 350px) {
    display: grid;
    grid-template-columns: ${Array(3 + 1)
      .fill("")
      .join("1fr ")};
  }

  grid-gap: 10px;
`;

interface Props {
  stepData: StepIndexData;
  roleData: RoleData;
  yourID: number;
  step: FeedbackScoreStep;
  stepContent: StepContent;
  text?: string;
}

const FeedbackScoreStep: FC<Props> = ({ stepData, roleData, yourID, step, stepContent, text }) => {
  const core = store.use.core();
  const inPerson = store.use.inPerson();
  const timeOnStep = store.use.timeOnStep();
  const stepTiming = store.use.stepTiming();
  const locale = store.use.locale();
  const invitation = store.use.invitation();

  const [buttonLoading, setButtonLoading] = useState<number | null>(null);
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    setShowContent(false);
    const timeout = setTimeout(() => {
      setShowContent(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [stepContent?.text]);

  const clickButton = (score: number) => {
    setButtonLoading(score as number);
    doAction(core, "done", step.id, yourID, score.toString(), setButtonLoading);
  };

  const role = step?.shareContent ? "EVERYONE_ELSE" : roleData[yourID].role;
  const content = step.content[role];

  const lowDescription = `1 = ${stepContent.descriptionLow}`;
  const highDescription = `${content.maxValue} = ${stepContent.descriptionHigh}`;

  // If we're out of time, then select a default score
  const youDone = stepData?.[core.currentUser.id]?.done;
  if (!youDone && !inPerson && timeOnStep >= stepTiming && invitation.autoAdvanceSteps) {
    doAction(core, "done", step.id, yourID, "7", setButtonLoading);
  }

  // If we're done, show we're waiting for the partner
  if (stepData && stepData[yourID]?.done) {
    console.debug("[FeedbackScoreStep] we're done so returning actions");
    return (
      <Wrapper className="showContent">
        <Button className="secondary" disabled={true}>
          <FormattedMessage
            description="Feedback Score Step button text"
            id="feedback_score_step.content_waiting_for_partner"
            defaultMessage="Waiting for partner"
          />
        </Button>
      </Wrapper>
    );
  }

  const classes: string[] = [];
  if (inPerson) classes.push("inPerson");
  if (showContent) classes.push("showContent");
  const classesString = classes.join(" ");
  return (
    <Wrapper className={classesString}>
      {!inPerson && <Overlay />}

      <ScoreWrapper id="question-cards-wrapper" className={inPerson ? "inPerson" : ""} key="scoreslist" width={70}>
        {text && <ScoreText>{text}</ScoreText>} {/* needed in in-person mode */}
        <Description>
          <DescriptionLow>{lowDescription}</DescriptionLow>
          <DescriptionHigh>{highDescription}</DescriptionHigh>
        </Description>
        <ScoresList columns={5}>
          {Array.from(Array(content.maxValue).keys())
            .map((score) => score + 1)
            .map((score) => {
              return (
                <LoadingButton
                  key={score}
                  style={{ width: "45px", height: "42px" }}
                  onClick={() => clickButton(score)}
                  loading={buttonLoading == score}
                >
                  {score}
                </LoadingButton>
              );
            })}
        </ScoresList>
        {!inPerson && (
          <ContinueEarly
            secondsCountdownTime={stepTiming}
            hideBarTime={stepTiming}
            timeOnStep={timeOnStep}
            locale={locale}
            setContinueEarly={null}
            autoAdvanceSteps={invitation.autoAdvanceSteps}
          />
        )}
      </ScoreWrapper>
    </Wrapper>
  );
};

export default FeedbackScoreStep;
