import React from "react";
import { BreakoutRoomIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { FlowsModal } from "../FlowsModal/FlowsModal";
import { modalToggle } from "../Modal/Modals";
import { store, useStore } from "../../../core/store";

export const BreakoutButton = () => {
  const showBreakoutModal = store.use.showModals().includes("BreakoutModal");

  return (
    <>
      <MenuButton
        buttonIcon={<BreakoutRoomIcon stroke={showBreakoutModal ? "#FFA100" : "white"} />}
        onClick={() => {
          modalToggle("BreakoutModal");
          useStore.setState({ showExternalContent: false, showChat: false });
        }}
        className={showBreakoutModal ? "selected" : ""}
      >
        <FormattedMessage description="Menu button to create a breakout" id="breakout_button" defaultMessage="Flows" />
      </MenuButton>
      <FlowsModal />
    </>
  );
};
