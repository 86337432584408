import React, { useRef, FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import times from "../../../assets/icons/times.svg";
import { store, useStore } from "../../../core/store";
import { SendMessage } from "../../../assets/icons/Icons";
import * as linkify from "linkifyjs";

const ChatPane = styled.div`
  display: flex;
  flex-direction: column;
  user-select: text;

  .not-present {
    opacity: 0.6;
  }

  #messages {
    flex-grow: 2;

    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;

    > div {
      width: 80%;
      border: 1px solid #eee;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
      font-size: 16px;
    }

    div.you {
      background-color: #f6f6ff;
      margin-left: 11%;
    }

    div.them {
      background-color: #f6fff6;
    }

    div.facilitator {
      width: 80%;
      margin: 0px auto 10px auto;
      background-color: #eee;
    }
  }

  form {
    max-width: 100%;
    margin: 0px;
    display: flex;
    padding-bottom: 10px;

    input {
      height: 30px;
      flex-grow: 2;
      margin-right: 10px;
      background-color: #eee;
      border: 1px solid #ccc;

      color: #000;
      padding: 7px 12px;
      font-size: 14px;
      border-radius: 3px;
    }

    button {
      color: #fff;
    }

    input {
      width: 10px;
    }

    button {
      padding: 1px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #empty-messages {
    background-color: #eee;
    border-radius: 7px;
    padding: 8px 14px;
    font-size: 14px;
    margin-bottom: 20px;

    display: flex;

    i {
      font-size: 30px;
      margin-right: 10px;
      position: relative;
      top: 5px;
    }
  }

  #presence-indicator {
    font-size: 14px;
    margin-bottom: 10px;
  }

  width: 365px;
  max-width: 50vw;
  position: fixed;
  right: 5px;
  top: 42px;
  bottom: 69px;
  margin: auto;

  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  color: #000;
  z-index: 90;

  animation: slide 0.4s forwards;
  animation-delay: 0s;

  padding-left: 10px;
  padding-right: 10px;

  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  #close-button {
    position: absolute;
    top: 0px;
    right: 4px;
    border-radius: 100px;
    background-color: #fff;
    color: #000;
    border: 0px;
    width: 45px;
    height: 45px;

    &:hover {
      background-color: #ddd;
    }
  }

  button,
  .button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #f19903;
    border: 1px solid #f19903;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;

    &:disabled {
      background-color: #999;
      border-color: #999;
      opacity: 0.9;
    }
  }
`;

interface ChatProps {
  onSendMessage: (message: string) => void;
  yourId: number;
  partnerPresent: boolean;
}

function renderMessage(messageText: string): any {
  const links = linkify.find(messageText);
  if (links.length == 0) {
    return <> {messageText}</>;
  }
  const firstLink = links[0];
  const linkText = firstLink.value;
  return (
    <>
      {firstLink.start >= 1 && <>{messageText.substring(0, firstLink.start)}</>}
      <a href={linkText} target={"_blank"} rel="noreferrer">
        {linkText}
      </a>
      {firstLink.start + linkText.length < messageText.length && (
        <>{renderMessage(messageText.substring(firstLink.end))}</>
      )}
    </>
  );
}

const Chat: FC<ChatProps> = ({ onSendMessage, yourId, partnerPresent }) => {
  const isOpen = store.use.showChat();
  const messages = store.use.chatMessages();

  const chatInputRef = useRef<HTMLInputElement>(null);

  function sendMessage(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (chatInputRef.current && chatInputRef.current.value) {
      onSendMessage(chatInputRef.current.value);
    }

    if (chatInputRef.current) {
      chatInputRef.current.value = "";
    }
  }

  function chatClass(id: string | number) {
    if (id === "facilitator") return "facilitator";
    if (id === yourId) return "you";
    return "them";
  }

  return (
    <>
      {isOpen ? (
        <ChatPane id="chat-pane" className="side-panel">
          <button
            id="close-button"
            onClick={() => {
              useStore.setState({ showChat: false });
            }}
          >
            <img height="18px" src={times} style={{ position: "relative", top: "2px" }} />
          </button>

          <h2>
            <FormattedMessage description="Chat heading text" id="chat.heading" defaultMessage="Chat" />
          </h2>

          <div id="messages">
            {messages.map((message, i) => {
              return (
                <div key={i} className={chatClass(message.user_id)}>
                  <div>
                    <small>
                      {message.user_id === yourId ? (
                        <FormattedMessage
                          description="Username label for chat"
                          id="chat.username_you"
                          defaultMessage="You"
                        />
                      ) : (
                        message.name
                      )}
                    </small>
                  </div>

                  {renderMessage(message.message)}
                </div>
              );
            })}
          </div>

          <form onSubmit={sendMessage} className={partnerPresent ? "present" : "not-present"}>
            <input
              id="chat"
              type="text"
              ref={chatInputRef}
              autoFocus
              autoComplete="off"
              disabled={!partnerPresent}
              style={{ WebkitUserSelect: "text" }}
            />
            <button id="chat" disabled={!partnerPresent}>
              <SendMessage />
            </button>
          </form>
          <div id="presence-indicator">
            <FormattedMessage
              description="Chat presence text describing partner status"
              id="chat.partner_status"
              defaultMessage="Your partner is: {status}"
              values={{
                status: partnerPresent ? (
                  <FormattedMessage
                    description="Online status text when partner is online"
                    id="chat.online"
                    defaultMessage="Online"
                  />
                ) : (
                  <FormattedMessage
                    description="Offline status text when partner is offline"
                    id="chat.offline"
                    defaultMessage="Offline (Chat Disabled)"
                  />
                ),
              }}
            />
          </div>
        </ChatPane>
      ) : undefined}
    </>
  );
};

export default Chat;
