import React, { FC, useCallback, useEffect, useState } from "react";
import { store, useStore } from "../../core/store";
import { useShallow } from "zustand/react/shallow";
import { AudioProgress } from "../UI/AudioProgress";
import styled from "styled-components";
import {
  isChoicesStep,
  isContentStep,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
  isIframeStep,
} from "../../types/type_guards";
import ChoiceStep from "../UI/Steps/ChoiceStep";
import { IFrameStep } from "../UI/Steps/IFrameStep";
import { VideoGrid } from "../UI/VideoGrid/VideoGrid";
import Actions from "../UI/Actions/Actions";
import Username from "../UI/Username/Username";
import FeedbackScoreStep from "../UI/Steps/FeedbackScoreStep";
import FeedbackWordsStep from "../UI/Steps/FeedbackWordsStep";
import { Branding } from "../UI/Branding/Branding";
import { useScreenShare } from "@daily-co/daily-react";

interface VideoSessionProps {
  isWaitingForPartner: boolean;
}

const VideoSessionWrapper = styled.div`
  width: 100%;
  height: 100%;

  &.medium,
  &.large {
    &.modalVisible {
      padding-left: 405px;
    }

    &.chatVisible {
      padding-right: 390px;
    }
  }

  box-sizing: border-box;
`;

export const VideoSession: FC<VideoSessionProps> = ({ isWaitingForPartner }) => {
  const participants = store.use.participants();
  const stepContent = store.use.stepContent();
  const roleData = store.use.roleData();
  const stepData = store.use.stepData();
  const deviceClassification = store.use.deviceClassification();
  const currentStep = store.use.currentStep();
  const sessionConsumerIsConnected = store.use.sessionConsumerIsConnected();
  const locale = store.use.locale();
  const currentAudioObject = store.use.currentAudioObject();
  const showExternalContent = store.use.showExternalContent();
  const showBreakoutModal = store.use.showModals().includes("BreakoutModal");
  const showChat = store.use.showChat();
  const recording = store.use.recording();
  const transcribing = store.use.transcribing();
  const iframeVisible = store.use.iframeVisible();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const core = store.use.core();

  const isSomeonePresent = core.isSomeonePresent;

  const [autoHideEngaged, setAutoHideEngaged] = useState(false);
  const [disableAutoHide, setDisableAutoHide] = useState(false);

  //This will show all buttons and menu in UI and restart autohide timer
  const resetAutoHide = useCallback(() => {
    setAutoHideEngaged(false);
    setDisableAutoHide(false);

    if (currentAudioObject || isFeedbackScoreStep(currentStep) || isFeedbackWordsStep(currentStep)) {
      setDisableAutoHide(true);
    } else {
      setDisableAutoHide(false);
    }
  }, [currentAudioObject]);

  // useEffect(() => {
  //   // const timeout = setTimeout(() => {
  //   //   setAutoHideEngaged(true);
  //   // }, 14000);

  //   return () => {
  //     timeout && clearTimeout(timeout);
  //   };
  // }, [resetAutoHide, autoHideEngaged, roleData[currentUser?.id]?.role, currentStep?.id, sessionConsumerIsConnected]);

  useEffect(() => {
    resetAutoHide();
    if (currentAudioObject) {
      setDisableAutoHide(true);
    }
  }, [roleData[currentUser?.id]?.role, currentStep?.id, currentAudioObject, sessionConsumerIsConnected]);

  const [currentText, setCurrentText] = React.useState<string | undefined>("");
  const [showUI, setShowUI] = React.useState<boolean>(true);

  // This is about animating the text when new text comes in.
  useEffect(() => {
    if (!isIframeStep(currentStep) && isContentStep(currentStep) && currentStep.audioFile[locale]?.url) {
      setCurrentText(stepContent?.text);
      return;
    }
    setShowUI(false);

    const timeout = setTimeout(() => {
      setShowUI(true);
      setCurrentText(stepContent?.text);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [stepContent?.text]);

  const { screens } = useScreenShare();
  const showScreenOrContent = screens.length > 0 || showExternalContent;
  const classes = [];
  classes.push(deviceClassification.size);
  if (showScreenOrContent) classes.push("screensharing");
  if (showBreakoutModal) classes.push("modalVisible");
  if (showChat) classes.push("chatVisible");

  return (
    <VideoSessionWrapper className={classes.join(" ")}>
      <VideoGrid
        onMouseMove={resetAutoHide}
        recordOption={recording ? "recording" : transcribing ? "transcribing" : null}
        participants={participants}
        showUI={showUI && (!autoHideEngaged || disableAutoHide) && sessionConsumerIsConnected}
        contentAreaContent={
          <>
            {currentStep?.type == "ContentStep" && currentStep.audioFile && (
              <AudioProgress role={roleData?.[currentUser.id]?.role} step={currentStep} userID={currentUser.id} />
            )}
            {currentText &&
              !isFeedbackScoreStep(currentStep) &&
              !isFeedbackWordsStep(currentStep) &&
              !iframeVisible && <p>{currentText}</p>}
          </>
        }
        buttonAreaContent={
          stepContent?.actions &&
          stepContent.actions.length > 0 && (
            <Actions
              wrapperClasses={"nohide"}
              disableAutoHide={(disable) => {
                setDisableAutoHide(disable);
              }}
            />
          )
        }
      />

      {invitation.lobby &&
        Object.values(participants).filter((p) => !invitation.waitingRoomEnabled || p.location == "session").length ===
          1 &&
        !invitation.solo &&
        !invitation.muteAll && (
          <Branding
            fill={"#888"}
            style={{
              width: "250px",
              height: "46px",
              marginBottom: "20px",
              marginLeft: "-125px",
              marginTop: "-23px",
              top: "50%",
              left: "50%",
              position: "absolute",
              zIndex: 0,
            }}
          />
        )}

      {(currentUser.dailySessionID || participants[currentUser.id]) &&
        (deviceClassification.size !== "small" || Object.values(participants).length <= 2) && (
          <Username
            name={"You"}
            participantLocation={"session"}
            solo={false}
            isLocal={true}
            dailyID={currentUser.dailySessionID || participants[currentUser.id].dailySessionID} // TODO VG: Fix this. This isn't right. Something must be going wrong somewhere
            roleData={roleData[currentUser.id]}
            userId={currentUser.id}
          />
        )}

      <IFrameStep />

      {isChoicesStep(currentStep) && sessionConsumerIsConnected && (
        <ChoiceStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          choices={stepContent?.choices}
        />
      )}
      {sessionConsumerIsConnected && !isWaitingForPartner && isFeedbackScoreStep(currentStep) && (
        <FeedbackScoreStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}
      {sessionConsumerIsConnected && !isWaitingForPartner && isFeedbackWordsStep(currentStep) && (
        <FeedbackWordsStep
          stepData={stepData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}
    </VideoSessionWrapper>
  );
};
