import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { store } from "../../../core/store";

export const ExternalContentTileContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 45%;
  background-color: #fff;

  border-radius: 10px;
  overflow: hidden;
  z-index: 10;

  transition: width 0.3s ease;

  @media (max-width: 1250px) {
    width: 65%;
  }

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  iframe {
    height: 100%;
    width: 100%;
    border: none;
    z-index: 100;
  }
`;

export const ExternalContentTile: FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const externalContentUrl = store.use.externalContentUrl();
  const showExternalContent = store.use.showExternalContent();
  const rooms = store.use.rooms();

  const completedRooms = rooms.filter((room) => room.status === "completed").length;

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = externalContentUrl || "";
    }
  }, [completedRooms]);

  return (
    externalContentUrl &&
    showExternalContent && (
      <ExternalContentTileContainer>
        <iframe ref={iframeRef} src={externalContentUrl} />
      </ExternalContentTileContainer>
    )
  );
};
