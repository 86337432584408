import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { toastConfig } from "react-simple-toasts";

import { useHandleNoSleep } from "../../hooks/useHandleNoSleep";
import { Prejoin } from "../Prejoin/Prejoin";
import { VideoSession } from "../VideoSession/VideoSession";
import { InPersonSession } from "../InPersonSession/InPersonSession";
import { store } from "../../core/store";
import Music from "../UI/Music/Music";
import { useGoToFeedback } from "../../hooks/useGoToFeedback";
import WaitingForPartner from "../UI/WaitingForPartner/WaitingForPartner";
import { ConnectionIssueBadge } from "../UI/Badge/ConnectionIssueBadge";
import { useSetDeviceClassification } from "../../hooks/useSetDeviceClassification";
import { dropParticipant } from "../../core/SessionChannel/dropParticipant";
import DropParticipantModal from "../UI/DropParticipantModal/DropParticipantModal";
import Menu from "../UI/Menu/Menu";
import ManageParticipantsModal from "../UI/Menu/ManageParticipantModal";
import { LobbyStatusBadge } from "../UI/Badge/LobbyStatusBadge";
import { Chime } from "../UI/Chime/Chime";
import { ConfirmCloseRoomModal } from "../UI/FlowsModal/ConfirmCloseRoomModal";
import { QrCodeModal } from "../UI/QR/QrCodeModal";
import { ConfirmReturnRoomModal } from "../UI/FlowsModal/ConfirmReturnRoomModal";
import { PrejoinInPerson } from "../Prejoin/PrejoinInPerson";
import { ConfirmLeaveModal } from "../UI/ConfirmLeaveModal/ConfirmLeaveModal";
import { EndScreen } from "../UI/EndScreen/EndScreen";
import { ReportPartnerModal } from "../UI/ReportPartnerModal/ReportPartnerModal";
import { ReportPartnerEndScreen } from "../UI/ReportPartnerEndScreen/ReportPartnerEndScreen";
import { WaitingRoomScreen } from "../UI/WaitingRoomScreen/WaitingRoomScreen";
import Chat from "../UI/Chat/Chat";
import { sendChatMessage } from "../../core/SessionChannel/sendChatMessage";

toastConfig({
  theme: "dark",
  className: "toast",
  offsetY: 70,

  clickClosable: true,
  maxVisibleToasts: 1,
});

const AppWrapper = styled.div`
  height: 100%;

  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;

  height: calc(100% - 35px);
  margin-top: 35px;
`;

const App: FC = () => {
  console.debug("[session-ui]: App render");

  const core = store.use.core();
  const currentUser = store.use.currentUser();
  const iframeVisible = store.use.iframeVisible();
  const inPerson = store.use.inPerson();
  const invitation = store.use.invitation();
  const mode = store.use.mode();
  const participants = store.use.participants();
  const sessionConsumerIsConnected = store.use.sessionConsumerIsConnected();
  const sessionEndDate = store.use.sessionEndDate();
  const showConfirmCloseBreakoutModal = store.use.showModals().includes("ConfirmCloseBreakout");
  const showConfirmLeaveModal = store.use.showModals().includes("ConfirmLeave");
  const showDropParticipantModal = store.use.showDropParticipantModal();
  const showManageParticipantsModal = store.use.showModals().includes("ManageParticipants");
  const showReportPartnerModal = store.use.showModals().includes("ReportPartner");
  const showReturnRoomModal = store.use.showModals().includes("ConfirmReturnRoom");

  const isSomeonePresent = core.isSomeonePresent;
  const isLobby = invitation.lobby;
  const isWaitingForPartner = !invitation.solo && !isSomeonePresent && !iframeVisible && !isLobby;

  useHandleNoSleep();
  useGoToFeedback();
  useSetDeviceClassification();

  const joinedParticipants = useMemo(() => {
    return participants && Object.values(participants).filter((x) => x?.joined);
  }, [participants]);

  const breakoutRoomHashId = participants[currentUser.id]?.breakoutRoomHashId;
  const isBreakoutRoom = breakoutRoomHashId === invitation.hashedID;

  return (
    <AppWrapper>
      <Music />
      <Chime />
      <ConnectionIssueBadge isConnected={sessionConsumerIsConnected} />
      <Chat partnerPresent={isSomeonePresent} yourId={currentUser.id} onSendMessage={sendChatMessage} />

      {mode === "session" && (
        <LobbyStatusBadge title={invitation.title || ""} inRoom={isBreakoutRoom} expirationDate={sessionEndDate} />
      )}

      {mode === "prejoin" && !inPerson && <Prejoin />}
      {mode === "prejoin" && inPerson && <PrejoinInPerson />}
      {mode === "session" && inPerson && !isWaitingForPartner && <InPersonSession />}
      {mode === "session" && !inPerson && <VideoSession isWaitingForPartner={isWaitingForPartner} />}
      {mode === "end" && <EndScreen />}
      {mode === "waitingRoom" && <WaitingRoomScreen />}
      {mode === "reportPartner" && <ReportPartnerEndScreen />}

      {showDropParticipantModal > -1 && (
        <DropParticipantModal dropParticipant={dropParticipant.bind(undefined, showDropParticipantModal)} />
      )}

      {showConfirmCloseBreakoutModal && <ConfirmCloseRoomModal />}
      {showReturnRoomModal && <ConfirmReturnRoomModal />}
      {showConfirmLeaveModal && <ConfirmLeaveModal inRoom={isBreakoutRoom} />}
      {showReportPartnerModal && <ReportPartnerModal />}

      {showManageParticipantsModal && (
        <ManageParticipantsModal
          joinedParticipants={joinedParticipants}
          dropParticipant={dropParticipant}
          yourId={currentUser.id}
        />
      )}
      <QrCodeModal />

      {mode === "session" && isWaitingForPartner && (
        <WaitingForPartner
          acceptedTime={"unknown"} // TODO
          partnersGettingReady={
            Object.values(participants)
              .filter((x) => x.id !== currentUser.id)
              .filter((x) => x.location == "ready-room").length
          }
          partnersInSession={
            Object.values(participants)
              .filter((x) => x.id !== currentUser.id)
              .filter((x) => x.location == "session").length
          }
          partnerCount={Object.values(participants).filter((x) => x.id !== currentUser.id).length}
          parentInvitationID={invitation.parentInvitationID}
          invitationAutoGroup={invitation.autoGroup}
          invitationID={invitation.id}
          invitationHashedID={invitation.hashedID}
        />
      )}

      {mode === "session" && <Menu />}
    </AppWrapper>
  );
};

export default App;
