// AudioHandler - This component renders the audio progress bar at the top of the
//                session. It's fully responsible for figuring out what (if anything)
//                to load and play based on the current step.
//
//                It also listens to updates from the cable, to stop / start
//                and seek the audio when the partner does as well.

import { throttle } from "throttle-typescript";
import * as React from "react";
import { isContentStep } from "../../../types/type_guards";
import styled from "styled-components";
import soundIcon from "../../../assets/sound.png";
import { FC, useCallback, useEffect, useState, memo } from "react";
import { useAnimationFrame } from "../../../hooks/useAnimationFrame";
import { store, useStore } from "../../../core/store";
import { Subscription } from "../../../core/SessionChannel/setupSessionChannel";
import { can } from "../../../helpers/can";

const AudioProgressWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 10;

  padding: 16px;
  border-radius: 8px;
  background-color: rgba(33, 33, 33, 0.5);
  align-items: center;

  #sound-icon {
    display: none;
  }

  @media (max-width: 736px) {
    &.inpersonAudioProgressWrapper {
      #sound-icon {
        display: block;
      }
      top: 10%;
      background: none;
      flex-wrap: wrap;
      width: 80vw;
      img {
        width: 100%;
        max-width: 183px;
        margin: auto;
        display: block;
      }
    }
  }
  .play-progress {
    display: flex;
    width: 100%;
  }

  .play-button {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 15px;
    top: 2px;
    position: relative;
    margin-right: 16px;

    border-color: transparent transparent transparent #f19903;
    transition: 100ms all ease;
    cursor: pointer;

    border-style: solid;
    border-width: 8px 0 8px 14px;
  }

  .play-button.playing {
    border-style: double;
    border-width: 0px 0 0px 13px;
  }

  .play-button:hover {
    border-color: transparent transparent transparent #f19903;
  }

  .progress-wrapper {
    height: 10px;
    width: 100%;
    background-color: rgba(80, 80, 80, 0.5);
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }

  .progress-bar {
    background-color: #f19903;
    width: 0%;
    height: 10px;
    border-radius: 5px;
  }
`;

// This defines how often the client side reports the audio position to the server
// in miliseconds.
const AUDIO_PROGRESS_UPDATE_RATE = 1000;

interface Props {
  role?: Roles;
  step: AnyStep;
  userID: number;
}

const throttledReport = throttle((subscriptionPerform: Subscription["perform"], step: AnyStep, seconds: number) => {
  subscriptionPerform("report_audio_progress", {
    stepId: step.id,
    progress: seconds,
  });
}, AUDIO_PROGRESS_UPDATE_RATE);

const AudioProgress: FC<Props> = ({ step, role, userID }) => {
  const [currentAudioPercentage, setCurrentAudioPercentage] = useState(0);

  const locale = store.use.locale();
  const skipBroadcastRef = store.use.skipBroadcastRef();
  const currentAudioObject = store.use.currentAudioObject();
  const narrating = store.use.narrating();
  const audioProgress = store.use.audioProgress();
  const narrationVolume = store.use.narrationVolume();
  const core = store.use.core();
  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();

  const updateFrame = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (deltaTime: number) => {
      if (currentAudioObject) {
        const currentTime = currentAudioObject.currentTime;

        if (typeof currentTime === "number") {
          const percentage = (currentTime / currentAudioObject.duration) * 100;
          setCurrentAudioPercentage(percentage);
        }
      }
    },
    [currentAudioObject, audioProgress],
  );

  useEffect(() => {
    if (!currentAudioObject) return;

    if (!isNaN(audioProgress) && currentAudioObject.currentTime === 0) {
      console.debug("setting initial ap.", audioProgress);
      currentAudioObject.currentTime = audioProgress;
      updateFrame(0);
    }
  }, [audioProgress, step, currentAudioObject, updateFrame]);

  useEffect(() => {
    if (currentAudioObject) {
      currentAudioObject.volume = narrationVolume;
    }
  }, [narrationVolume, currentAudioObject]);

  useEffect(() => {
    if (
      isContentStep(step) &&
      (step.audioFile[locale] || step.audioFile["en"]) &&
      currentAudioObject &&
      audioProgress &&
      !isNaN(audioProgress)
    ) {
      skipBroadcastRef.current = true;
    }
  }, [currentAudioObject, step, audioProgress, useStore, locale]);

  // Play / Pause depending on narration state
  useEffect(() => {
    if (!currentAudioObject) return;

    if (narrating) {
      console.debug("[session-ui > audio progress]: Playing audio object");

      const timeLeft = currentAudioObject.duration - currentAudioObject.currentTime;
      if (timeLeft !== 0) {
        currentAudioObject.play().catch(() => {}); // TODO !
      }
    }

    if (!narrating) {
      console.debug("[session-ui > audio progress]: Pausing audio object");

      currentAudioObject.pause();
    }
  }, [narrating, currentAudioObject]);

  useAnimationFrame(updateFrame, currentAudioObject && narrating);

  const clickPauseAudio = useCallback(() => {
    console.debug("[session-ui > audio progress]: User requested pause audio");
    skipBroadcastRef.current = false;
    useStore.setState({ narrating: false });
  }, [useStore]);

  const clickPlayAudio = useCallback(() => {
    // console.debug("[session-ui > audio progress]: User requested play audio");
    skipBroadcastRef.current = false;
    useStore.setState({ narrating: true });
  }, [useStore]);

  const systemPauseAudio = useCallback(() => {
    console.debug("[session-ui > audio progress]: System requested pause audio");
    if (!currentAudioObject) return;

    const timeLeft = currentAudioObject.duration - currentAudioObject.currentTime;
    if (timeLeft === 0) {
      skipBroadcastRef.current = true;
      core.subscription?.perform("set_audio_time", {
        currentAudioTime: currentAudioObject.currentTime,
        skipToast: true,
      });
      core.subscription?.perform("do_action", {
        actionType: "done",
        userID: userID,
        stepID: step.id,
        choice: "",
      });
    } else {
      if (!skipBroadcastRef.current) core.subscription?.perform("pause_audio");
    }

    skipBroadcastRef.current = false;
    if (narrating) {
      useStore.setState({ narrating: false });
    }
  }, [useStore, currentAudioObject, userID, step, narrating]);

  const systemPlayAudio = useCallback(() => {
    // console.debug("[session-ui > audio progress]: System requested play audio");
    if (!narrating) useStore.setState({ narrating: true });
    if (!skipBroadcastRef.current) core.subscription?.perform("play_audio");
    skipBroadcastRef.current = false;
  }, [useStore, narrating]);

  const audioObjectTimeUpdate = useCallback(
    (e: Event) => {
      if (role !== "A") return;
      if (!core.subscription) return;

      if (e) {
        const element = e.currentTarget as HTMLAudioElement;
        throttledReport(core.subscription!.perform.bind(core.subscription), step, element.currentTime);
      }
    },
    [role, step],
  );

  useEffect(() => {
    if (!currentAudioObject) return;

    console.debug("[session-ui > audio progress]: Installing system event listeners");
    currentAudioObject.addEventListener("pause", systemPauseAudio, true);
    currentAudioObject.addEventListener("play", systemPlayAudio, true);
    currentAudioObject.addEventListener("timeupdate", audioObjectTimeUpdate, true);

    return () => {
      if (!currentAudioObject) return;
      console.debug("[session-ui > audio progress]: Removing system event listeners");
      currentAudioObject.removeEventListener;
      currentAudioObject.removeEventListener("pause", systemPauseAudio, true);
      currentAudioObject.removeEventListener("play", systemPlayAudio, true);
      currentAudioObject.removeEventListener("timeupdate", audioObjectTimeUpdate, true);
    };
  }, [systemPauseAudio, systemPlayAudio, currentAudioObject, audioObjectTimeUpdate]);

  const clickSetAudioTime = useCallback(
    (e: React.MouseEvent) => {
      // Don't move the audio bar if the user actions are restricted
      if (!can("advanceAudio", currentUser, invitation)) return;

      const rect = e.currentTarget.getBoundingClientRect();
      const width = rect.width;
      const x = e.clientX - rect.left;

      const desiredProportion = x / width;

      let desiredTime = 0;
      if (currentAudioObject) {
        desiredTime = currentAudioObject.duration * desiredProportion;
        currentAudioObject.currentTime = desiredTime;
        core.subscription?.perform("set_audio_time", { currentAudioTime: desiredTime });
        updateFrame(0);
      }

      // console.debug(`[session-ui > audio progress]: User requested set audio time: ${desiredTime}`);
    },
    [currentAudioObject, updateFrame],
  );

  if (!isContentStep(step)) return null;

  if (!step.audioFile[locale] && !step.audioFile["en"]) return null;

  return (
    <AudioProgressWrapper className="audioProgress">
      <img id="sound-icon" src={soundIcon} />

      <div className="play-progress">
        {can("advanceAudio", currentUser, invitation) && (
          <div
            id="play-button"
            onClick={narrating ? clickPauseAudio : clickPlayAudio}
            className={(narrating ? "playing " : "") + "play-button"}
          ></div>
        )}
        <div className="progress-wrapper" onClick={clickSetAudioTime}>
          <div
            id="audio-progress-bar"
            data-testid="audio-progress-bar"
            className="progress-bar"
            style={{ width: currentAudioPercentage + "%" }}
          ></div>
        </div>
      </div>
    </AudioProgressWrapper>
  );
};

export default memo(AudioProgress);
