import React, { memo, useEffect, useRef, useState } from "react";
import { useMediaTrack } from "@daily-co/daily-react";

const TileAudio = memo(function TrackAudio({
  id,
  isLocal,
  isScreen,
}: {
  id: string;
  isLocal?: boolean;
  isScreen?: boolean;
}) {
  const audioTrack = useMediaTrack(id, isScreen ? "screenAudio" : "audio");
  const [firstTime, setFirstTime] = useState(true);

  const audioElement = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioElement.current;
    if (!audio || !audioTrack?.persistentTrack) return;
    /*  The track is ready to be played. We can show audio of the participant in the UI.*/
    audio.srcObject = new MediaStream([audioTrack?.persistentTrack]);
    //This state update helps reload audioElement mute volume code block below
    if (firstTime) {
      setFirstTime(false);
    }
  }, [audioTrack?.persistentTrack]);
  // It's important that 'firstTime' is not part of this useEffect dependency list.
  // TODO: Figure out how to make audio be loud without relying on this behaviour?

  if (!audioElement) return null;
  //For local audio, making volume 0, so it will not echoed back
  //This is added so we can play audio file. Not sure why it is necessary :).
  //This code is mainly for iPhone Safari narration sound issue where voice was coming from microphone.
  if (audioElement && audioElement.current && isLocal) {
    audioElement.current.volume = 0;
    audioElement.current.muted = true;
  }
  return (
    <>
      <audio autoPlay ref={audioElement} />
    </>
  );
});

export default TileAudio;
