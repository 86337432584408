import React, { Suspense } from "react";
import { Root } from "react-dom/client";
import { AxiosError } from "axios";
import { DailyCall } from "@daily-co/daily-js";
import { ErrorBoundary } from "@sentry/react";

import { SFX } from "../assets/SFX";

import { loadNarrationAudios } from "../lib/narrationAudioLoader";
import { DEFAULT_LOCALE } from "../lib/defaults";
import { hideLoading } from "../lib/hideLoading";

import { Providers } from "../components/Providers/Providers";
import { Locales, wsCreateIntl } from "./intl";
import { FallbackError } from "../components/UI/ErrorMessages/FallbackError";

import { forceNextStep } from "./SessionChannel/forceNextStep";
import { initialRequests } from "./ScheduleApp/initialRequests";
import { onMessage } from "./SessionChannel/onMessage";
import { onParticipantJoined, onParticipantLeft } from "./Daily/onParticipants";
import { PostMessageListener } from "./PostMessageListener/postMessageListener";
import { setupCallObject } from "./Daily/setupCallObject";
import { resetSessionChannel, setupSessionChannel, Subscription } from "./SessionChannel/setupSessionChannel";
import { useStore } from "./store";
import { onActiveSpeakerChange } from "./Daily/onActiveSpeakerChange";
import { InterfaceRules, InterfaceRulesResponse } from "../helpers/warmspaceLogic";
import toast from "react-simple-toasts";
import App from "../components/App/App";

export class Core {
  // Initial params
  clientID: string;
  hashedInvitationID: string;
  root: Root;

  // Initial data
  invitation: InvitationResponse;
  currentUser: UserResponse;
  invitationResolver?: ((value: InvitationResponse) => void) | null;
  currentUserResolver?: (value: UserResponse) => void;

  // UI state
  error: Error | AxiosError | unknown | undefined;
  locale: Locales;
  interfaceRules: InterfaceRulesResponse;
  timeOnStepInterval: ReturnType<typeof setInterval> | undefined;
  isSomeonePresent: boolean = false;

  // Translations / Locales
  intl: ReturnType<typeof wsCreateIntl>;

  // Daily / Realtime features
  callObject?: DailyCall;
  callObjectPromise?: Promise<DailyCall>;
  postMessageListener?: PostMessageListener;
  subscription?: Subscription;
  subscriptionPromise?: Promise<Subscription>;

  constructor(
    root: Root,
    clientID: string,
    hashedInvitationID: string,
    response: {
      invitation: InvitationResponse;
      currentUser: UserResponse;
    },
  ) {
    // Initial params
    this.clientID = clientID;
    this.hashedInvitationID = hashedInvitationID;
    this.root = root;

    // Initial request repsonses
    this.invitation = response.invitation;
    this.currentUser = response.currentUser;

    // UI state
    this.error = undefined;
    this.locale = DEFAULT_LOCALE;
    this.interfaceRules = { allowChat: false, allowModeSwitching: false };

    // Translations / Locales
    this.intl = wsCreateIntl(this.locale);
  }

  reinit() {
    // this.invitation = {} as InvitationResponse;
    this.locale = DEFAULT_LOCALE;
    // this.subscription = undefined;

    useStore.setState({
      currentStep: undefined,
      showModals: [],
      showMatchingConfig: false,
      sessionEndDate: undefined,
      showExternalContent: false,
      stepContent: undefined,
      iframeVisible: false,
    });
  }

  private static instance: Core;

  static GetInstance() {
    return Core.instance;
  }

  // Core.Boot kicks off the initial REST API requests and initializes a new Core instance.
  // I wanted instances of Core to be able to rely on the fact that the initial requests
  // had been made and the responses were available.
  static async Boot(root: Root, clientID: string, hashedInvitationID: string) {
    try {
      const responses = await initialRequests(hashedInvitationID);
      const core = new Core(root, clientID, hashedInvitationID, responses);
      this.instance = core;
      await core.boot();
      return core;
    } catch (e) {
      root.render(<FallbackError error={e} />);
      hideLoading();
    }
  }

  // core.boot continues the booting process, sets up the realtime features,
  // initialises bits of UI state that rely on the initial requests,
  // and eventually renders the app.
  async boot(transitioning = false) {
    try {
      this.locale = (this.currentUser.locale || DEFAULT_LOCALE) as Locales;
      this.intl = wsCreateIntl(this.locale);

      // Let the user know that recording is on when they join
      // TODO: run this after the prejoin screen
      const recordingStateChanged =
        useStore.getState().recording != this.invitation.recording ||
        useStore.getState().transcribing != this.invitation.transcribing;

      // When users are first joining, let them know that recording is on
      if (!transitioning && recordingStateChanged) {
        const action =
          !this.invitation.recording && !this.invitation.transcribing
            ? "stop_recording"
            : this.invitation.recording && this.invitation.transcribing
              ? "start_recording_and_transcription"
              : this.invitation.recording
                ? "start_recording"
                : "start_transcription";

        setTimeout(
          () =>
            onMessage.bind(this)({
              toggledByParticipantId: -1,
              toggledByParticipantName: "System",
              action: action,
              errorMessage: undefined,
            }),
          3000,
        );
      }

      const externalContentUrl =
        !useStore.getState().externalContentUrl && this.invitation.lobby
          ? this.buildExternalContentUrl(this.invitation.lobbyReportHashId, this.invitation.lobbySequenceInstanceHashId)
          : null;

      useStore.setState({
        core: this,
        inPerson: this.invitation.inPerson || false,
        locale: this.locale,
        muteAll: this.invitation.muteAll,
        restrictUserActions: this.invitation.restrictUserActions,
        timeOnStep: 0,
        autoJoin: this.invitation.autoJoin,
        raisedHands: this.invitation.raisedHands,
        recording: this.invitation.recording,
        transcribing: this.invitation.transcribing,
        invitation: this.invitation,
        currentUser: this.currentUser,
        externalContentUrl: externalContentUrl,

        interfaceRules: InterfaceRules(
          { solo: this.invitation?.solo, anytimeSession: this.invitation?.anytimeSession },
          { disableModeSwitching: this.invitation?.disableModeSwitching, disableChat: this.invitation?.disableChat },
        ),
      });

      // Start the timeOnStep timer
      if (this.timeOnStepInterval) clearInterval(this.timeOnStepInterval);

      this.timeOnStepInterval = setInterval(() => {
        if (useStore.getState().mode !== "session") return; // Don't increment timeOnStep if not in session mode.
        if (!this.isSomeonePresent && !this.invitation.solo) return; // Don't increment timeOnStep if no one is present.
        useStore.setState((state) => ({ timeOnStep: state.timeOnStep + 1 })); // Increment timeOnStep.
      }, 1000);

      // Set up postMessage listener (iFrame API)
      this.postMessageListener = new PostMessageListener({
        forceNextStep: forceNextStep.bind(this),
        disconnectCall: () => this.callObject?.leave(),
        setHashedInvitationID: (hashedInvitationID) => this.joinNewInvitation(hashedInvitationID),
      });

      const state = useStore.getState();
      // If not in person, set up the call object.
      if (!state.inPerson) {
        if (!transitioning) useStore.setState({ mode: "prejoin" });

        if (!state.callJoined) {
          // Set up the Daily call object.
          if (this.callObjectPromise) await this.callObjectPromise;

          this.callObjectPromise = setupCallObject(
            this.invitation,
            this.clientID,
            this.currentUser.name,
            onParticipantJoined.bind(this),
            onParticipantLeft.bind(this),
            onActiveSpeakerChange.bind(this),
            transitioning,
          );
          this.callObject = await this.callObjectPromise;

          // TODO VG: Fix this. This isn't right. Something must be going wrong somewhere
          // this.currentUser.dailySessionID = this.callObject.participants()?.local?.session_id;
          this.currentUser.dailySessionID =
            this.callObject.participants()?.local?.session_id ||
            useStore.getState().participants[this.currentUser.id].dailySessionID;
        }
        // Set to mute if that's the default for this invitation
        if (this.invitation.muteAll) {
          this.callObject!.setLocalAudio(false);
        }
      }

      // Connecting to the schedule-app sessionChannel
      if (!this.subscription) {
        console.debug(
          `[core] connecting to sessionChannel - creating new. hashedInvitationID: ${this.hashedInvitationID}`,
        );
        this.subscriptionPromise = setupSessionChannel(onMessage.bind(this));
        this.subscription = await this.subscriptionPromise;
      }

      this.renderApp();
      hideLoading();
    } catch (e: unknown) {
      console.error(e);
      this.root.render(<FallbackError error={e} />);
      hideLoading();
    }
  }

  async joinCall(transitioning: boolean = false) {
    try {
      await this.loadAudio(); // TODO: Make it ok to not await this call.

      if (!this.subscription) await this.subscriptionPromise;

      // Get the latest state again. Because we now have loaded audios, so
      // this would allow the current audio object to be set properly if there is one.
      this.subscription?.perform("get_state", {});

      const inPerson = useStore.getState().inPerson;
      if (!inPerson) await this.callObjectPromise;

      if (inPerson) {
        useStore.setState({ callJoined: false });
      }
      if (!inPerson && !useStore.getState().callJoined && this.callObject && process.env.NODE_ENV !== "cypress-test") {
        // If we haven't joined the Daily call yet, then do so now
        await this.callObject.join({
          userData: { warmspaceUserID: this.currentUser.id },
          subscribeToTracksAutomatically: false,
          // receiveSettings: {
          //   base: { video: { layer: 1 } }, // default: { layer: 2 }
          // },
        });

        // Mute if entering an invitation with 4 or more people.
        // The number below is 5 because the current user is included in the count.
        if (Object.values(useStore.getState().participants).length >= 5) {
          toast("Microphone muted.");
          this.callObject.setLocalAudio(false);
        }

        useStore.setState({ callJoined: true });
      }

      // Don't update the location if we're transitioning between invitations,
      // in case the user hasn't clicked the initial join button yet
      if (!transitioning) {
        const modeAndLocation =
          this.invitation.lobby && this.invitation.waitingRoomEnabled && useStore.getState().mode !== "session"
            ? "waitingRoom"
            : "session";
        useStore.setState({ mode: modeAndLocation });
        this.subscription!.perform("update_location", { location: modeAndLocation });
      }

      this.renderApp();
    } catch (e: unknown) {
      console.error(e);
      this.root.render(<FallbackError error={e} />);
      hideLoading();
    }
  }

  async joinNewInvitation(hashedInvitationID: string) {
    try {
      console.debug(`[session-ui]: Attempting to load invitation "${hashedInvitationID}".`);
      this.reinit();
      window.history.replaceState(null, "", `/${hashedInvitationID}`);
      // showLoading();

      this.hashedInvitationID = hashedInvitationID;

      await this.callObjectPromise;
      if (!this.subscription) return;

      const invitationPromise = new Promise<InvitationResponse>((resolve) => {
        this.invitationResolver = resolve;
      });
      // const currentUserPromise = new Promise<UserResponse>((resolve) => {
      //   this.currentUserResolver = resolve;
      // });
      console.debug(`[core] connecting to sessionChannel - updating. hashedInvitationID: ${this.hashedInvitationID}`);
      this.subscription.perform("update_socket", { invitationHashId: this.hashedInvitationID });

      this.invitation = await invitationPromise.then((result) => result);
      this.invitationResolver = null;
      this.invitation.localDate = new Date();

      // const controller = new AbortController();
      // try {
      //   this.invitation = await getInvitation(hashedInvitationID, controller.signal);
      //   this.invitation.localDate = new Date();
      // } catch (e) {
      //   controller.abort();
      //   throw e;
      // }

      await this.boot(true);
      if (!useStore.getState().inPerson) await this.joinCall(true);
    } catch (e: unknown) {
      console.error(e);
      this.root.render(<FallbackError error={e} />);
      // hideLoading();
    }
  }

  async loadAudio() {
    console.debug("[session-ui]: Loading audio files.");

    // Load sound effects.
    SFX.loadAudios();

    // Load narration.
    const narrationAudioFiles = await loadNarrationAudios(this.invitation.audioPaths);
    useStore.setState({ narrationAudio: narrationAudioFiles });
  }

  renderApp() {
    this.root.render(
      <Providers callObject={this.callObject} intl={this.intl}>
        <ErrorBoundary fallback={(e) => <FallbackError error={e.error} />}>
          <Suspense fallback={<div />}>
            <App />
          </Suspense>
        </ErrorBoundary>
      </Providers>,
    );
  }

  buildExternalContentUrl(reportHashId: string, sequenceInstanceHashId: string, cacheKey: string = "1") {
    const backendUrl = `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}`;
    return `${backendUrl}/analytics/report/${reportHashId}?&analytics-sequence-instance-hash-id=${sequenceInstanceHashId}&analytics-frame-content=1&analytics-cache-key=${cacheKey}`;
  }
}

// Try to make hot reloading work properly. Doesn't really work yet though.
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.dispose(function () {
    resetSessionChannel();
  });
}
