import React from "react";
import styled from "styled-components";
import CameraButton from "./CameraButton";
import MicButton from "./MicButton";
import MoreButton from "./MoreButton";
import ScreenShareButton from "./ScreenShareButton";
import ChatButton from "./ChatButton";
import { store } from "../../../core/store";
import { BreakoutButton } from "./BreakoutButton";
import ParticipantsButton from "./ParticipantsButton";
import AnalyticsButton from "./AnalyticsButton";
import { can } from "../../../helpers/can";
import RaiseHandButton from "./RaiseHandButton";
import AddPartnerButton from "./AddPartnerButton";
import RecordButton from "./RecordButton";
import { ReportPartnerButton } from "./ReportPartnerButton";

const Wrapper = styled.div`
  height: 60px;
  margin: auto;
  z-index: 95;
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;

  .inner {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
    box-sizing: border-box;
    margin: auto;
  }
`;

export const Menu = () => {
  const inPerson = store.use.inPerson();
  const interfaceRules = store.use.interfaceRules();
  const deviceClassification = store.use.deviceClassification();
  const externalContentUrl = store.use.externalContentUrl();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();

  const isLobby = invitation.lobby;

  const isBiggerScreen = deviceClassification.size !== "small";

  return (
    <Wrapper>
      <div className="inner">
        {inPerson && !isLobby && <AddPartnerButton />}
        {!inPerson && <CameraButton />}
        {!inPerson && can("manageOwnMuteState", currentUser, invitation) && <MicButton />}
        {isLobby && can("manageBreakouts", currentUser, invitation) && <BreakoutButton />}
        {!isLobby && !inPerson && invitation.enableReportPartner && <ReportPartnerButton />}
        {isBiggerScreen && <ParticipantsButton />}
        {!inPerson && isBiggerScreen && <RecordButton />}
        {isLobby && externalContentUrl && <AnalyticsButton />}
        {interfaceRules.allowChat && !inPerson && <ChatButton />}
        {!inPerson && isBiggerScreen && isLobby && <RaiseHandButton />}
        {invitation.allowScreenshare && !inPerson && (!externalContentUrl || isBiggerScreen) && <ScreenShareButton />}
        <MoreButton />
      </div>
    </Wrapper>
  );
};

export default Menu;
